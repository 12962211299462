import data from "./data.json";

function Row(props) {
  return (
    <li class="list-group-item">
      <div class="d-flex justify-content-between align-items-lg-center flex-column flex-lg-row p-3">
        <div class="order-2 order-lg-1">
          <h5 class="mt-0 font-weight-bold mb-2">{props.item.title}</h5>
          <p class="font-italic text-muted mb-0 small px-5">{props.item.description}</p>
        </div>
        <img src={props.item.img} alt={props.item.title} width="200" class="ml-lg-5 order-1 order-lg-2" />
      </div>
    </li>
  );
}

export function Products(props) {
  var items = [];

  const inFilter = (item, filter) => {
    if (!filter || filter.length === 0) return true;

    var res = true;

    filter.forEach((felement) => {
      if (felement.vals.length > 0) {
        if (Array.isArray(item[felement.field])) {
          var intersection = felement.vals.filter((x) => item[felement.field].includes(x));
          if (intersection.length < (felement.shouldContain === "all" ? felement.vals.length : 1)) res = res & false;
        } else {
          res = res & felement.vals.includes(item[felement.field]);
        }
      }
    });
    return res;
  };
  data.forEach((item) => {
    if (inFilter(item, props.filter)) items.push(<Row item={item}></Row>);
  });
  if (items.length > 0) return <ul class="list-group shadow radius8">{items}</ul>;
  else
    return (
      <div class="alert alert-warning" role="alert">
        Для точного подбора просим обратиться на{" "}
        <a href="mailto:polycom@marvel.ru" class="alert-link">
          polycom@marvel.ru
        </a>
        .
      </div>
    );
}
