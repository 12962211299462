export function SideFilter(props) {
  var filters = props.filters;

  const getCheckedFilters = (field, count) => {
    var vals = [];
    for (var i = 0; i < count; i++) {
      var el = document.getElementById(field + i.toString());
      if (el && el.checked === true) {
        vals.push(el.value);
      }
    }
    return vals;
  };

  const checkRadio = (selectedField, selectedIndex) => {
    var selectedElement = document.getElementById(selectedField.field + selectedIndex.toString());
    if (selectedElement.checked && selectedField.oneVal) {
      //disable others
      for (var i = 0; i < selectedField.vals.length; i++) {
        if (i !== selectedIndex) document.getElementById(selectedField.field + i.toString()).checked = false;
      }
    }
  };

  const checkFilter = (selectedField, selectedIndex) => {
    checkRadio(selectedField, selectedIndex);
    var dataFilter = [];
    filters.forEach((item) => {
      var vals = getCheckedFilters(item.field, item.vals.length);
      dataFilter.push({ field: item.field, vals: vals, shouldContain: item.shouldContain });
    });
    // create and dispatch the event
    var event = new CustomEvent("Filter", {
      detail: {
        filter: dataFilter,
      },
    });
    document.dispatchEvent(event);
  };

  return (
    <div id="sidebar">
      {filters.map((item, index) => {
        return (
          <div class="py-2 border-bottom ml-3">
            <h6 class="font-weight-bold">{item.title}</h6>
            <div id="orange">
              <span class="fa fa-minus"></span>
            </div>
            <form>
              {item.vals.map((val, index) => {
                return (
                  <div class="form-group">
                    {" "}
                    <input
                      type="checkbox"
                      id={item.field + index.toString()}
                      value={val}
                      onClick={() => checkFilter(item, index)}
                    />{" "}
                    <label>{val}</label>
                  </div>
                );
              })}
            </form>
          </div>
        );
      })}
    </div>
  );
}
