import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React from "react";

export class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.filters = props.filters;
    this.dataFilter = props.dataFilter;
  }

  switchFilter(currentIndex, nextIndex) {
    var current_fs = document.getElementById("mobFilter" + this.filters[currentIndex].field);
    var next_fs = document.getElementById("mobFilter" + this.filters[nextIndex].field);
    this.filters.forEach((element) => {
      document.getElementById("progressStep" + this.filters[currentIndex].field).classList.remove("active");
    });
    document.getElementById("progressStep" + this.filters[nextIndex].field).classList.add("active");
    next_fs.style.display = "block";
    current_fs.style.display = "none";
  }

  fnext(currentIndex) {
    this.switchFilter(currentIndex, currentIndex + 1);
  }

  fprev(currentIndex) {
    this.switchFilter(currentIndex, currentIndex - 1);
  }

  //---------------------------------------
  checkFilter(selectedField, selectedIndex, val, checked) {
    if (checked) {
      if (selectedField.oneVal || !this.dataFilter[selectedField.field]) this.dataFilter[selectedField.field] = [val];
      else {
        if (!this.dataFilter[selectedField.field].includes(val)) this.dataFilter[selectedField.field].push(val);
      }
    } else {
      if (this.dataFilter[selectedField.field] && this.dataFilter[selectedField.field].includes(val)) {
        var idx = this.dataFilter[selectedField.field].indexOf(val);
        this.dataFilter[selectedField.field].splice(idx, 1);
      }
    }
    var eFilter = [];
    this.filters.forEach((element) => {
      if (this.dataFilter[element.field]) {
        eFilter.push({
          field: element.field,
          vals: this.dataFilter[element.field],
          shouldContain: element.shouldContain,
        });
      }
    });
    var event = new CustomEvent("Filter", {
      detail: {
        filter: eFilter,
      },
    });
    document.dispatchEvent(event);
    console.log("dispatch Filter");

    this.setState({ dataFilter: this.dataFilter, filters: this.filters });
  }

  render() {
    return (
      <section id="mobile-filter" class="pb-10">
        <div class="row  text-center ">
          <div class="col-lg-8 mx-auto">
            <div class="card px-0 pb-0 mb-3">
              <div class="row">
                <div class="col-md-12 mx-0">
                  <div id="msform">
                    <ul id="progressbar">
                      {this.filters.map((item, index) => {
                        return <li class={index === 0 ? "active" : ""} id={"progressStep" + item.field}></li>;
                      })}
                    </ul>
                    {this.filters.map((item, index) => {
                      return (
                        <fieldset id={"mobFilter" + item.field}>
                          <div class="card shadow">
                            <div class="card-body">
                              <h5 class="card-title">{item.title}</h5>
                              {item.vals.map((val, idx) => {
                                return (
                                  <div class="row justify-content-center pt-10 pb-10">
                                    <div class="col-3">
                                      <BootstrapSwitchButton
                                        width={75}
                                        checked={
                                          this.dataFilter[item.field] && this.dataFilter[item.field].includes(val)
                                            ? true
                                            : false
                                        }
                                        onlabel=" "
                                        offlabel=" "
                                        onChange={(checked) => {
                                          this.checkFilter(item, idx, val, checked);
                                        }}
                                      />
                                    </div>
                                    <div class="col-6">{val}</div>
                                  </div>
                                );
                              })}
                            </div>{" "}
                          </div>{" "}
                          <button
                            disabled={index === 0 ? true : false}
                            class="previous action-button lni-arrow-left"
                            onClick={() => {
                              this.fprev(index);
                            }}
                          ></button>
                          <button
                            disabled={index === this.filters.length - 1 ? true : false}
                            class="next action-button lni-arrow-right"
                            onClick={() => {
                              this.fnext(index);
                            }}
                          ></button>
                        </fieldset>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <script>$('.btn-toggle').bootstrapSwitch();</script>
      </section>
    );
  }
}
