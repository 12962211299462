import "./App.css";
import { Fragment } from "react";
import { Products } from "./products.js";
import { SideFilter } from "./sidefilter";
import { Filter } from "./filter";
import React from "react";
import data from "./data.json";

function App() {
  var _filterVer = 1;
  var [filterVer, setFilterVer] = React.useState(1);
  var [filter, setFilter] = React.useState([]);

  var filters = [
    {
      field: "size",
      title: "Вариант использования",
      oneVal: true,
      shouldContain: "",
      vals: [],
    },
    {
      field: "platform",
      title: "Платформа",
      oneVal: false,
      shouldContain: "all",
      vals: [],
    },
    {
      field: "monitors",
      title: "Количество мониторов",
      oneVal: true,
      shouldContain: "",
      vals: [],
    },
    {
      field: "autofocus",
      title: "Автофокус",
      oneVal: true,
      shouldContain: "",
      vals: [],
    },
  ];

  data.forEach((element) => {
    filters.forEach((fitem) => {
      if (!Array.isArray(element[fitem.field])) {
        if (!fitem.vals.includes(element[fitem.field]) && element[fitem.field].length > 0) {
          fitem.vals.push(element[fitem.field]);
        }
      } else {
        element[fitem.field].forEach((val) => {
          if (!fitem.vals.includes(val) && val.length > 0) {
            fitem.vals.push(val);
          }
        });
      }
    });
  });

  const onFilter = (e) => {
    console.log("Filter event");
    console.log(JSON.stringify(e.detail.filter));
    setFilter(e.detail.filter);
    _filterVer++;
    setFilterVer(_filterVer);
  };

  if (window.onFilter) document.removeEventListener("Filter", window.onFilter);
  window.onFilter = onFilter;
  document.addEventListener("Filter", window.onFilter);

  return (
    <Fragment>
      <section id="filter_section" class="pt-60 pb-100">
        <div class="container">
          <div class="row justify-content-center pb-20">
            <div class="text-center">
              <h1>Подберите свое оборудование</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <Filter dataFilter={[]} filters={filters}></Filter>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <SideFilter filters={filters} />
            </div>
            <div class="col-lg-9">
              <Products data={data} filter={filter} filterVer={filterVer} />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default App;
